import Framework7 from "framework7"

document.addEventListener("DOMContentLoaded", function(event) {
  const app = new Framework7({
    root: "#app",
    id: "net.itdaa.www",
    name: "itdaa",
    clicks: {
      externalLinks: "a[type=\"external\"]",
      // externalLinks: "a[type!=\"internal\"]",
    },
    panel: {
      swipeCloseOpposite: false
    },
    toast: {
      closeButtonText: "확인"
    },
    view: {
      router: false,
    },
    theme: "ios",
    touch: {
      fastClicks: false,
      activeState: false,
      disableContextMenu: false
    },
    on: {
      init: function () {
      },

      pageInit: function () {
      },
    }
  })

  app.views.create(".view-main", {
    iosDynamicNavbar: false,
    router: false,
  })

  window.app = app
})
