import { Controller } from "stimulus"
import ahoy from "ahoy.js"

export default class extends Controller {
  static values = { eventName: String, eventProperties: Object }

  connect() {
    this.trackVisit()
  }

  trackVisit() {
    // ex) ahoy.track("Viewed content", { content_id: 1 })
    ahoy.track(this.eventNameValue, this.eventPropertiesValue)
  }
}
