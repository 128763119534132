import { Controller } from "stimulus"
import { tns } from "tiny-slider/src/tiny-slider"

export default class extends Controller {
  connect() {
    tns({
      container: ".slider-container",
      autoWidth: true,
      mouseDrag: true,
      controls: false,
      nav: false,
      preventScrollOnTouch: "auto"
    })
  }
}
