/*!
 * Determine if an element is in the viewport
 * @param {Node} elem The element
 * @return {Boolean} Returns true if element is in the viewport
 */
const isInViewport = (element) => {
  const distance = element.getBoundingClientRect()
  return (
    distance.top >= 0 &&
    distance.left >= 0 &&
    distance.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    distance.left <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export default isInViewport
