document.addEventListener("DOMContentLoaded", function(event) {
  app.searchbar.create({
    el: "#searchbar-autocomplete",
    customSearch: true,
    backdrop: false
  })

  app.$(document).on("submit", "form.searchbar", function(e) {
    e.preventDefault()
    app.$(this).submit()
  })

  app.autocomplete.create({
    openIn: "dropdown",
    inputEl: "#searchbar-autocomplete input[type=\"search\"]",
    valueProperty: "name",
    textProperty: "name",
    limit: 10,
    source: function (query, render) {
      var results = []
      if (query.length === 0) {
        render(results)
        return
      }

      app.request({
        url: "/searchs.json",
        method: "GET",
        dataType: "json",
        data: {
          q: query,
        },
        success: function (data) {
          results = data
          render(results)
        }
      })
    },
    on: {
      change: function () {
        app.$("#searchbar-autocomplete").submit()
      }
    }
  })
})
