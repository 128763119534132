import { Controller } from "stimulus"
import { tns } from "tiny-slider/src/tiny-slider"

export default class extends Controller {
  static values = { container: String }

  connect() {
    tns({
      container: this.containerValue,
      items: 2,
      gutter: 10,
      edgePadding: 15,
      mouseDrag: true,
      controls: false,
      nav: false,
      loop: false,
      preventScrollOnTouch: "auto"
    })
  }
}
