import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"]

  OPTIONS = {
    threshold: 0.5
  }

  connect() {
    const io = this.isMobile() ? new IntersectionObserver(this.mobileObserver, this.OPTIONS) : new IntersectionObserver(this.desktopObserver, this.OPTIONS)
    const targetArrays = this.contentTargets
    targetArrays.forEach(link => {
      io.observe(link)
    })
  }

  isMobile() {
    return document.body.clientWidth <= 768 ? true : false
  }

  // https://developer.mozilla.org/ko/docs/Web/API/Intersection_Observer_API
  desktopObserver(entries) {
    const navigations = document.getElementsByClassName("sticky-nav")
    entries.forEach(entry => {
      const navTarget = Array.from(navigations).find(nav => nav.hash == `#${entry.target.id}`)
      if (entry.isIntersecting) {
        navTarget.classList.add("bg-gray-100", "font-bold")
      } else {
        navTarget.classList.remove("bg-gray-100", "font-bold")
      }
    })
  }

  mobileObserver(entries){
    const navigations = document.getElementsByClassName("sticky-nav")
    entries.forEach(entry => {
      const navTarget = Array.from(navigations).find(nav => nav.hash == `#${entry.target.id}`)
      if (entry.isIntersecting) {
        navTarget.classList.add("tab-link-active")
      } else {
        navTarget.classList.remove("tab-link-active")
      }
    })
  }
}
