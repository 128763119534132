// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// require("@rails/ujs").start()
// require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "core-js/stable"
import "regenerator-runtime/runtime"
import "jquery-datetimepicker/build/jquery.datetimepicker.full"
import "cocoon-js"
import "chartkick/chart.js"

import "whatwg-fetch"
import Framework7 from "framework7"
import Autocomplete from "framework7/components/autocomplete/autocomplete.js"
import Input from "framework7/components/input/input.js"
import Panel from "framework7/components/panel/panel.js"
import Searchbar from "framework7/components/searchbar/searchbar.js"
import Toast from "framework7/components/toast/toast.js"
import * as Activestorage from "@rails/activestorage"
import Rails from "@rails/ujs"

Framework7.use([Autocomplete, Input, Panel, Searchbar, Toast])
Activestorage.start()
window.Rails = Rails
Rails.start()

import "../appsignal.js.erb"
import "../init"
import "../searchbar_autocomplete"

import "../controllers/index.js.erb"

//Froala 에디터 생성
/* eslint-disable */
$(function () {
  new FroalaEditor("#mentor-post-froala-editor", {
    key: "WE1B5dF3G3A2C8C8D6ciufbeC8hbB-13uF2C1G1I1A10C1D6A1A5D5==",
    attribution: false,
    heightMin: "400px",
    pasteAllowedStyleProps: [],
    imageUploadParams: {"authenticity_token": $("meta[name=csrf-token]").attr("content")},
    imageUploadURL: "/attachments/upload",
    imageUploadMethod: "POST",
    imageDefaultWidth: "100%",
    imageDefaultAlign: "center",
    imageAddNewLine: true,
    zIndex: "9990",
    toolbarSticky: false,
    imageResizeWithPercent: true,
    videoResponsive: true,
    videoDefaultWidth: "100%",
    fontSize: ["8", "10", "12", "14", "16.5", "18", "30", "60", "96"],
    colorsBackground: [
      "#61BD6D", "#1ABC9C", "#54ACD2", "#2C82C9", "#9365B8", "#475577", "#CCCCCC",
      "#41A85F", "#00A885", "#3D8EB9", "#2969B0", "#553982", "#28324E", "#000000",
      "#F7DA64", "#FBA026", "#EB6B56", "#E25041", "#ff2d55", "#A38F84", "#EFEFEF",
      "#FAC51C", "#F37934", "#D14841", "#B8312F", "#7C706B", "#D1D5D8", "#FFFFFF", "REMOVE"
    ],
    colorsStep: 7,
    colorsText: [
      "#61BD6D", "#1ABC9C", "#54ACD2", "#2C82C9", "#9365B8", "#475577", "#CCCCCC",
      "#41A85F", "#00A885", "#3D8EB9", "#2969B0", "#553982", "#28324E", "#000000",
      "#F7DA64", "#FBA026", "#EB6B56", "#E25041", "#ff2d55", "#A38F84", "#808080",
      "#FAC51C", "#F37934", "#D14841", "#B8312F", "#7C706B", "#D1D5D8", "#FFFFFF", "REMOVE"
    ],
    language: "ko",
    toolbarButtons: [
      ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript"],
      ["fontSize", "textColor"], ["paragraphFormat", "align", "formatOL", "formatUL", "quote"],
      ["insertLink", "embedly", "insertImage", "insertTable"],
      ["specialCharacters", "insertHR"], ["clearFormatting"], ["help"]
    ],
  })

  new FroalaEditor("#answer-froala-editor", {
    key: "WE1B5dF3G3A2C8C8D6ciufbeC8hbB-13uF2C1G1I1A10C1D6A1A5D5==",
    attribution: false,
    heightMin: "400px",
    pasteAllowedStyleProps: [],
    imageUploadParams: {"authenticity_token": $("meta[name=csrf-token]").attr("content")},
    imageUploadURL: "/attachments/upload",
    imageUploadMethod: "POST",
    imageDefaultWidth: "100%",
    imageDefaultAlign: "center",
    imageAddNewLine: true,
    zIndex: "9990",
    toolbarSticky: false,
    imageResizeWithPercent: true,
    videoResponsive: true,
    videoDefaultWidth: "100%",
    fontSize: ["8", "10", "12", "14", "16.5", "18", "30", "60", "96"],
    colorsBackground: [
      "#61BD6D", "#1ABC9C", "#54ACD2", "#2C82C9", "#9365B8", "#475577", "#CCCCCC",
      "#41A85F", "#00A885", "#3D8EB9", "#2969B0", "#553982", "#28324E", "#000000",
      "#F7DA64", "#FBA026", "#EB6B56", "#E25041", "#ff2d55", "#A38F84", "#EFEFEF",
      "#FAC51C", "#F37934", "#D14841", "#B8312F", "#7C706B", "#D1D5D8", "#FFFFFF", "REMOVE"
    ],
    colorsStep: 7,
    colorsText: [
      "#61BD6D", "#1ABC9C", "#54ACD2", "#2C82C9", "#9365B8", "#475577", "#CCCCCC",
      "#41A85F", "#00A885", "#3D8EB9", "#2969B0", "#553982", "#28324E", "#000000",
      "#F7DA64", "#FBA026", "#EB6B56", "#E25041", "#ff2d55", "#A38F84", "#808080",
      "#FAC51C", "#F37934", "#D14841", "#B8312F", "#7C706B", "#D1D5D8", "#FFFFFF", "REMOVE"
    ],
    language: "ko",
    toolbarButtons: [
      ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript"],
      ["fontSize", "textColor"], ["paragraphFormat", "align", "formatOL", "formatUL", "quote"],
      ["insertLink", "embedly", "insertImage", "insertTable"],
      ["specialCharacters", "insertHR"], ["clearFormatting"], ["help"]
    ],
  })
})
/* eslint-enable */
