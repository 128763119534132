import { Controller } from "stimulus"
export default class extends Controller {
  toggleForm() {
    var formBlock = document.getElementById("input-form")
    if (formBlock.classList.contains("login-block--display")) {
      formBlock.classList.remove("login-block--display")
    } else {
      formBlock.classList.add("login-block--display")
    }
  }
}
