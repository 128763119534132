import { Controller } from "stimulus"
import { tns } from "tiny-slider/src/tiny-slider"

export default class extends Controller {
  static values = { container: String }
  DESKTOP = { items: 3, edgePadding: 50 }
  MOBILE = { items: 1, edgePadding: 30 }

  connect() {
    tns({
      container: this.containerValue,
      gutter: 10,
      mouseDrag: true,
      controls: false,
      responsive :{
        700: this.DESKTOP,
        450: this.MOBILE,
        300: this.MOBILE
      },
      nav: false,
      loop: true,
      preventScrollOnTouch: "auto"
    })
  }
}
