import { Controller } from "stimulus"
import isInViewport from "./utils/viewport"

export default class extends Controller {
  static values = { src: String }
  static classes = [ "lazyloaded" ]

  connect() {
    this.lazyLoadImage()
  }

  lazyLoadImage() {
    if (isInViewport(this.element)) {
      this._loadImage(this.element)
    }
  }

  _loadImage() {
    if (!this.element.classList.contains(this.lazyloadedClass)) {
      this.element.src = this.srcValue
      this.element.classList.add(this.lazyloadedClass)
    }
  }
}
