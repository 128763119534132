import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["reviewText"]

  connect(){
    const reviewText = this.reviewTextTarget
    const numOfLineLimit = 80

    if(reviewText.clientHeight > numOfLineLimit){
      const reviewBody = reviewText.parentElement
      const showMoreDiv = this.setShowMore(reviewBody, reviewText)

      showMoreDiv.addEventListener("click", () => {
        if(showMoreDiv.innerText === "더보기"){
          this.showBody(reviewText, showMoreDiv)
          return
        }
        if(showMoreDiv.innerText === "접기"){
          this.hideBody(reviewText, showMoreDiv)
          return
        }
      })
    }
  }

  setShowMore = (reviewBody, reviewText) => {
    const showMoreStyleClasses = ["text-xs", "font-semibold", "text-gray-600", "underline","text-left", "mt-1", "cursor-pointer"]
    const showMoreStyleClassName = "vchatcp__thanks_body--review"
    const showMoreDiv = document.createElement("div")

    reviewText.classList.add(showMoreStyleClassName)
    reviewBody.appendChild(showMoreDiv)
    showMoreDiv.classList.add(...showMoreStyleClasses)
    showMoreDiv.innerText = "더보기"

    return showMoreDiv
  }

  hideBody = (reviewText, showMoreDiv) => {
      reviewText.classList.add("vchatcp__thanks_body--review")
      showMoreDiv.innerText = "더보기"
  }

  showBody = (reviewText, showMoreDiv) => {
      reviewText.classList.remove("vchatcp__thanks_body--review")
      showMoreDiv.innerText = "접기"
  }
}

