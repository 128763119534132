import { Controller } from "stimulus"
import { tns } from "tiny-slider/src/tiny-slider"

export default class extends Controller {
  connect() {
    let _this = this

    let slider = tns({
      container: ".slider-container",
      items: 1,
      mouseDrag: true,
      controls: false,
      navContainer: "#customize-thumbnails",
      navAsThumbnails: true,
      preventScrollOnTouch: "auto"
    })

    slider.events.on("transitionEnd", function(slider) {
      // get slider info
      let indexPrev = slider.indexCached
      let indexCurrent = slider.index

      // remove indexPrev background color
      _this.element.classList.remove(slider.slideItems[indexPrev].dataset.bannerBgClass)

      // add indexCurrent background color
      let bannerBg = slider.slideItems[indexCurrent].dataset.bannerBgClass
      _this.element.classList.add(bannerBg)
    })
  }
}
