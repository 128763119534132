import { Controller } from "stimulus"

export default class extends Controller {
  static values = { score: Number }
  static classes = [ "selected", "hover" ]

  connect() {
  }

  overScore() {
    const onStar = this.scoreValue // The star currently mouse on
    const stars = this.element.parentNode.children

    for (let element of stars) {
      const value = parseInt(element.dataset.ratingScoreValue)
      if (value <= onStar) {
        element.classList.add(this.hoverClass)
      } else {
        element.classList.remove(this.hoverClass)
      }
    }
  }

  outScore() {
    const stars = this.element.parentNode.children

    for (let element of stars) {
      element.classList.remove(this.hoverClass)
    }
  }

  selectScore() {
    const score = this.scoreValue // The star currently selected
    const stars = this.element.parentNode.children

    for (let i = 0; i < stars.length; i++) {
      stars[i].classList.remove(this.selectedClass)
    }

    for (let i = 0; i < score; i++) {
      stars[i].classList.add(this.selectedClass)
    }

    document.querySelector("input[name='review[rating]']").value = score
  }
}
