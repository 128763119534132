import { Controller } from "stimulus"
import tippy from "tippy.js"

const Z_INDEX_VALUE = 10000
const POPPER_MODIFIERS = [
  {
    name: "offset",
    options: { offset: [-1, 0] },
  }
]

const POPPER_OPTIONS = {
  strategy: "absolute",
  modifiers: POPPER_MODIFIERS,
}

export default class extends Controller {
  static targets = [ "list" ]

  connect() {
    const container = document.getElementById("tippy-container")
    const button = this.element
    const list = this.listTarget

    tippy(button, {
      appendTo: container,
      placement: "bottom-end",
      content: list,
      allowHTML: true,
      trigger: "click",
      arrow: false,
      zIndex: Z_INDEX_VALUE,
      popperOptions: POPPER_OPTIONS
    })
  }
}
