import { Controller } from "stimulus"
import { debounce } from "lodash"

export default class extends Controller {
  static targets = ["textBox", "textCountWithLimit", "onlyTextCount"]

  connect() {
    const textbox = this.textBoxTarget
    // https://stimulus.hotwired.dev/reference/targets#optional-targets
    if (this.hasOnlyTextCountTarget){
      this.onlyTextCountTarget.innerText = `${this.textBoxTarget.value.length}자`
      textbox.addEventListener("input", this.onlyTextCount)
      return
    }

    if (this.hasTextCountWithLimitTarget){
      this.textCountWithLimitTarget.innerText = `${this.textBoxTarget.value.length}/${this.textBoxTarget.maxLength}자`
      textbox.addEventListener("input", this.textCountWithLimit)
      return
    }
  }

  textCountWithLimit = () => {
    (debounce(() => {
      this.textCountWithLimitTarget.innerText = `${this.textBoxTarget.value.length}/${this.textBoxTarget.maxLength}자`
    },300))()
  }

  onlyTextCount = () => {
    (debounce(() => {
      this.onlyTextCountTarget.innerText = `${this.textBoxTarget.value.length}자`
    },300))()
  }
}
