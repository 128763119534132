import { Controller } from "stimulus"
import tippy from "tippy.js"

const Z_INDEX_VALUE = 13000
const POPPER_MODIFIERS = [
  {
    name: "offset",
    options: { offset: [0, 0] }
  }
]

const POPPER_OPTIONS = {
  strategy: "fixed",
  modifiers: POPPER_MODIFIERS
}

export default class extends Controller {
  static targets = ["child"]

  connect() {
    tippy(this.element, {
      placement: "bottom-end",
      content: this.childTarget,
      allowHTML: true,
      trigger: "click",
      arrow: false,
      zIndex: Z_INDEX_VALUE,
      popperOptions: POPPER_OPTIONS
    })
  }
}
