// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import Appsignal from "@appsignal/javascript"
import { installErrorHandler } from "@appsignal/stimulus"

const application = Application.start()
// const context = require.context("controllers", true, /_controller\.js$/)
const context = require.context("controllers", true, /_controller\.(js$|js\.erb$)/)
application.load(definitionsFromContext(context))

const appsignal = new Appsignal({
  key: "6a800f1a-3432-4131-97ac-0bcf51469a61"
})

installErrorHandler(appsignal, application)
