import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["empathyButton"]
  beforeRequest(){
    this.empathyButtonTarget.disabled = true
  }

  empathize(event){
    const [, , xhr] = event.detail

    if(!xhr.response.includes("sign_in")){
      this.empathyButtonTarget.outerHTML = xhr.response
    }
  }
}